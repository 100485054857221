import { MenuItem } from '@headlessui/react'
import type {
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  UIEvent,
} from 'react'

import { themeBackgroundBlurMap, themeTextColorMap } from '@mntn-dev/ui-theme'

import { cn } from '@mntn-dev/ui-utilities'
import { type TestIds, getTestProps } from '../../utils'
import { useInputStyles } from '../input/use-input-styles.ts'

type PopoutItemProps = Readonly<
  PropsWithChildren<
    {
      icon: ReactNode
      title: string
      onItemSelect?: (event: UIEvent) => void
    } & TestIds
  >
>

export const PopoutItem = ({
  onItemSelect,
  icon,
  title,
  dataTestId,
  dataTrackingId,
}: PopoutItemProps) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    if (event.key === 'Enter' || event.key === ' ') {
      onItemSelect?.(event)
    }
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onItemSelect?.(event)
  }

  const inputStyles = useInputStyles()

  return (
    <MenuItem {...getTestProps({ dataTestId, dataTrackingId })}>
      <div
        className={cn(
          inputStyles,
          'cursor-pointer min-w-44 gap-4 rounded-none first:rounded-t last:rounded-b',
          themeBackgroundBlurMap['blur-md']
        )}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        {icon}

        <span className={`text-sm ${themeTextColorMap.secondary}`}>
          {title}
        </span>
      </div>
    </MenuItem>
  )
}
