'use client'

import React from 'react'

import { cn } from '@mntn-dev/ui-utilities'
import type { TestIds } from '../../utils'
import { type UseFormFieldProps, useFormFieldControl } from '../form-field'
import { useInputStyles } from '../input/use-input-styles.ts'

type TextareaProps = Readonly<
  Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'readOnly'> &
    UseFormFieldProps &
    TestIds & {
      focus?: boolean
    }
>

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      dataTestId,
      dataTrackingId,
      disabled: disabledProp,
      id,
      hasError: hasErrorProp,
      hasSuccess: hasSuccessProp,
      hasWarning: hasWarningProp,
      readOnly: readOnlyProp,
      className,
      onBlur,
      focus,
      ...textAreaProps
    },
    ref
  ) => {
    const { hasError, hasSuccess, hasWarning, ...fieldProps } =
      useFormFieldControl<HTMLTextAreaElement>({
        disabled: disabledProp,
        id,
        hasError: hasErrorProp,
        hasSuccess: hasSuccessProp,
        hasWarning: hasWarningProp,
        dataTestId,
        dataTrackingId,
        onBlur,
        readOnly: readOnlyProp,
      })

    const inputStyles = useInputStyles({
      hasError,
      hasSuccess,
      hasWarning,
      disabled: fieldProps.disabled,
      readOnly: fieldProps.readOnly,
    })

    return (
      <textarea
        {...textAreaProps}
        {...fieldProps}
        className={cn(inputStyles, 'h-auto', className)}
        ref={ref}
      />
    )
  }
)

export { Textarea, type TextareaProps }
