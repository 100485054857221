import { type ThemeGapValue, spacingToPixels } from '@mntn-dev/ui-theme'

export function getShowMoreAttributes(
  chipContainer: HTMLDivElement,
  columnGapValue: ThemeGapValue
): [count?: number, offset?: number] {
  const nodes = Array.from(chipContainer.childNodes) as HTMLElement[]

  let totalWidth = 0
  let showMoreIndex: number | undefined
  let showMoreOffset = 0

  for (const [index, node] of nodes.entries()) {
    const last = index === nodes.length - 1
    const availableWidth = last
      ? chipContainer.clientWidth
      : chipContainer.clientWidth - 100
    if (totalWidth + node.clientWidth > availableWidth) {
      if (!node.className.includes('invisible')) {
        node.className += ' invisible'
      }

      if (!showMoreIndex) {
        showMoreOffset = totalWidth + spacingToPixels(columnGapValue)
        showMoreIndex = index
      }
    } else if (totalWidth + node.clientWidth <= availableWidth) {
      node.className = node.className.replace('invisible', '')
    }

    totalWidth += node.clientWidth
  }

  return [
    showMoreIndex ? nodes.length - showMoreIndex : undefined,
    showMoreOffset,
  ]
}
