import { useMemo } from 'react'

import {
  type GetInputStylesOptions,
  getInputStyles,
} from './get-input-styles.ts'

export const useInputStyles = ({
  disabled,
  readOnly,
  hasError,
  hasSuccess,
  hasWarning,
  height,
  minHeight,
  maxHeight,
}: GetInputStylesOptions = {}) => {
  return useMemo(
    () =>
      getInputStyles({
        disabled,
        readOnly,
        hasError,
        hasSuccess,
        hasWarning,
        height,
        minHeight,
        maxHeight,
      }),
    [
      disabled,
      readOnly,
      hasError,
      hasSuccess,
      hasWarning,
      height,
      minHeight,
      maxHeight,
    ]
  )
}
