'use client'

import { makeRange } from '@mntn-dev/utilities'

import { Radio, RadioGroup } from '@headlessui/react'
import { forwardRef } from 'react'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field/use-form-field-control.ts'
import { StarIcon } from '../icon/custom'

type RatingStarsProps = Readonly<
  UseFormFieldControlProps<HTMLElement> &
    TestIds & {
      ariaLabel: string
      total: number
      defaultValue?: number
      onChange?: (rating: number) => void
    }
>

const RatingStars = forwardRef<HTMLElement, RatingStarsProps>(
  (
    {
      dataTestId,
      dataTrackingId,
      disabled,
      id,
      hasError: hasErrorProp,
      ariaLabel,
      total,
      readOnly,
      defaultValue,
      onChange,
    }: RatingStarsProps,
    ref
  ) => {
    const range = makeRange(total).map((i) => i + 1)

    const {
      hasError: _,
      hasSuccess: __,
      hasWarning: ___,
      ...fieldProps
    } = useFormFieldControl<HTMLElement>({
      disabled,
      id,
      hasError: hasErrorProp,
      readOnly,
      dataTestId,
      dataTrackingId,
    })

    return (
      <RadioGroup
        className="flex gap-1.5"
        ref={ref}
        defaultValue={defaultValue}
        onChange={onChange}
        {...fieldProps}
      >
        {({ value }) => (
          <>
            {range.map((i) => (
              <Radio
                key={i}
                value={i}
                aria-label={`${i} ${ariaLabel}`}
                className="cursor-pointer"
                {...getTestProps({
                  dataTestId: `${dataTestId}-${i}`,
                  dataTrackingId: `${dataTrackingId}-${i}`,
                })}
              >
                <StarIcon
                  size="2xl"
                  active={!!value && value >= i}
                  color="info"
                />
              </Radio>
            ))}
          </>
        )}
      </RadioGroup>
    )
  }
)

export { RatingStars, type RatingStarsProps }
