import {
  type FocusEvent,
  type HTMLAttributes,
  useCallback,
  useMemo,
} from 'react'

import { getTestProps } from '../../utils/testing.ts'
import {
  type UseFormFieldProps,
  useFormFieldContext,
} from './use-form-field.ts'

type UseFormFieldControlProps<TElement extends HTMLElement> = Pick<
  HTMLAttributes<TElement>,
  'onBlur' | 'onFocus'
> &
  UseFormFieldProps

function useFormFieldControl<TElement extends HTMLElement>({
  dataTestId,
  dataTrackingId,
  disabled,
  hasError,
  hasSuccess,
  hasWarning,
  id,
  onBlur,
  onFocus,
  readOnly,
}: UseFormFieldControlProps<TElement>) {
  const context = useFormFieldContext()
  const setFocused = context?.setFocused
  const labelId = context?.labelId

  const handleBlur = useCallback(
    (event: FocusEvent<TElement>) => {
      if (setFocused) {
        setFocused(false)
      }

      if (onBlur) {
        onBlur(event)
      }
    },
    [onBlur, setFocused]
  )

  const handleFocus = useCallback(
    (event: FocusEvent<TElement>) => {
      if (setFocused) {
        setFocused(true)
      }

      if (onFocus) {
        onFocus(event)
      }
    },
    [setFocused, onFocus]
  )

  const describedBy = useMemo(() => {
    const ids: string[] = []

    if (hasError && context?.hasErrorText) {
      ids.push(context?.errorId)
    }

    if (context?.hasHelpText) {
      ids.push(context?.helpId)
    }

    return ids.join(' ')
  }, [
    context?.errorId,
    context?.hasErrorText,
    context?.hasHelpText,
    context?.helpId,
    hasError,
  ])

  const isDisabled = disabled ?? context?.disabled
  const isReadonly = readOnly ?? context?.readOnly
  const isInvalid = hasError ?? context?.hasError

  return {
    'aria-describedby': describedBy || undefined,
    'aria-disabled': isDisabled,
    'aria-invalid': isInvalid,
    'aria-labelledby': labelId,
    'aria-readonly': isReadonly,
    disabled: isDisabled,
    id: id ?? context?.id,
    hasError: isInvalid,
    hasSuccess: hasSuccess ?? context?.hasSuccess,
    hasWarning: hasWarning ?? context?.hasWarning,
    onBlur: handleBlur,
    onFocus: handleFocus,
    readOnly: isReadonly,
    ...getTestProps({
      dataTestId: dataTestId ?? context?.dataTestId,
      dataTrackingId: dataTrackingId ?? context?.dataTrackingId,
    }),
  }
}

export { useFormFieldControl, type UseFormFieldControlProps }
