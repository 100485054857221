import { ComboboxButton, ComboboxInput } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { cn } from '@mntn-dev/ui-utilities'
import { useRef } from 'react'

import { themeTextColorMap } from '@mntn-dev/ui-theme'
import type { UseFormFieldProps } from '../form-field/use-form-field.ts'
import { useInputStyles } from '../input/use-input-styles.ts'
import { Tooltip } from '../tooltip'
import { useSelectContext } from './use-select.ts'

export type SelectButtonProps = Pick<
  UseFormFieldProps,
  'hasError' | 'hasSuccess' | 'hasWarning' | 'readOnly' | 'disabled'
> & {
  open: boolean
}

export const SelectButton = ({
  disabled,
  readOnly,
  hasError,
  hasSuccess,
  hasWarning,
  open,
}: SelectButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { getSelectProps } = useSelectContext()
  const {
    selectedOptionIcon,
    selectedOptionLabel,
    placeholderText,
    selectQuery,
  } = getSelectProps()

  const clearInput = () => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.value = ''
      }
    }, 0)
  }

  const isPlaceholder = selectedOptionLabel === placeholderText

  const inputStyles = useInputStyles({
    hasError,
    hasSuccess,
    hasWarning,
    disabled,
    readOnly,
  })

  return open ? (
    <>
      <ComboboxInput
        autoFocus={true}
        ref={inputRef}
        className={cn(inputStyles, 'pr-8', open && 'rounded-b-none')}
        onChange={(event) => {
          selectQuery(event.target.value)
        }}
        onFocus={() => {
          clearInput()
          selectQuery('')
        }}
        onBlur={() => {
          clearInput()
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            if (inputRef.current) {
              inputRef.current.blur()
            }
          }
        }}
        placeholder={placeholderText}
      />
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronDownIcon
          className="text-primary-blue h-5 w-5"
          aria-hidden="true"
        />
      </span>
    </>
  ) : (
    <Tooltip content={isPlaceholder ? null : selectedOptionLabel} delay={2000}>
      <ComboboxButton
        className={cn(inputStyles, 'pr-8', open && 'rounded-b-none')}
      >
        <span className="flex items-center gap-4 overflow-hidden">
          {selectedOptionIcon && (
            <span className="text-primary-blue h-5 w-5 shrink-0">
              {selectedOptionIcon}
            </span>
          )}
          <span
            className={cn('text-med block truncate font-medium', {
              [themeTextColorMap.tertiary]:
                !selectedOptionLabel || isPlaceholder,
            })}
          >
            {selectedOptionLabel || placeholderText}
          </span>
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            className="text-primary-blue h-5 w-5"
            aria-hidden="true"
          />
        </span>
      </ComboboxButton>
    </Tooltip>
  )
}
