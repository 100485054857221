'use client'

import { type ReactElement, type ReactNode, useCallback } from 'react'

import { createContext } from '../../utils/context.ts'
import { type TestIds, getTestProps } from '../../utils/testing.ts'

type UseSelectProps = TestIds & {
  displayableOptions: Array<ReactElement>
  placeholderText: string
  selectedOptionIcon?: ReactNode
  selectedOptionLabel?: string
  selectQuery: (query: string) => void
}

function useSelect(props: UseSelectProps) {
  const {
    displayableOptions,
    placeholderText,
    selectedOptionIcon,
    selectedOptionLabel,
    selectQuery,
    dataTestId,
    dataTrackingId,
  } = props || {}

  const getSelectProps = useCallback(
    () => ({
      displayableOptions: displayableOptions,
      placeholderText: placeholderText,
      selectedOptionIcon: selectedOptionIcon,
      selectedOptionLabel: selectedOptionLabel,
      selectQuery: selectQuery,
      ...getTestProps({
        dataTestId,
        dataTrackingId,
      }),
    }),
    [
      displayableOptions,
      placeholderText,
      selectedOptionIcon,
      selectedOptionLabel,
      selectQuery,
      dataTestId,
      dataTrackingId,
    ]
  )

  return {
    getSelectProps,
  }
}

type UseSelectReturn = ReturnType<typeof useSelect>

const [SelectProvider, useSelectContext] = createContext<UseSelectReturn>({
  name: 'SelectContext',
})

export {
  SelectProvider,
  type UseSelectProps,
  type UseSelectReturn,
  useSelect,
  useSelectContext,
}
